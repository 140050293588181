import Enum from './enum'

/**
 * 一括操作対象
 */
class BulkOperationTarget extends Enum {
    static CHECKED = 1; // チェックした製品を
    static ALL = 2; // 検索結果全てを

    static values() {
        return {
            [this.CHECKED]: 'チェックした製品を',
            [this.ALL]: '検索結果全てを',
        }
    }
}

export default BulkOperationTarget;