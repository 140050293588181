/**
 * エクスポート定義詳細マスタ エンティティ
 */
import { isNud } from '@/utilities/typing';

class ExportLayoutDetail {
    export_layout_detail_id;
    field_key;
    field_header;
    field_sequence;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.export_layout_detail_id = properties.export_layout_detail_id;
        this.field_key = properties.field_key;
        this.field_header = properties.field_header;
        this.field_sequence = properties.field_sequence;
    }
}

export default ExportLayoutDetail;
