import Enum from './enum'

/**
 * 正誤表タイプ
 */
class ErrataType extends Enum {
    static FOR_USER = 1; // ユーザ向け
    static FOR_DISTRIBUTOR = 2; // 代理店向け

    static values() {
        return {
            [this.FOR_USER]: 'ユーザ向け',
            [this.FOR_DISTRIBUTOR]: '代理店向け',
        }
    }
}

export default ErrataType;
