<template>
    <div class="bg-light mb-3">
        <div class="mx-1 px-3 py-2 d-flex justify-content-between align-items-center">
            <div>
                該当 : {{ total }} {{ target }}
            </div>
            <div>
                <slot name="right"/>
            </div>
        </div>
        <slot name="bottom"/>
    </div>
</template>

<script>
export default {
    name: 'SearchResult',
    props: {
        total: {
            type: Number,
            default: 0
        },
        target: {
            type: String,
            default: '件'
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
