<template>
    <page-title
        icon="bi-search"
        title="製品一覧"
    >
    </page-title>

    <inline-loader v-if="fetch_loading"></inline-loader>
    <template v-else>
        <section class="section">
            <form @submit.prevent="search()">
                <div class="row align-items-end mb-3">
                    <div class="form-group col-md-4">
                        <label>製品コード</label>
                        <form-input
                            v-model="condition.code"
                        ></form-input>
                    </div>
                    <div class="form-group col-md-4">
                        <label>キーワード</label>
                        <form-input
                            v-model="condition.keyword"
                        ></form-input>
                    </div>
                    <div class="form-group col-md-8">
                        <label>カタログ掲載</label>
                        <div class="input-group">
                            <form-select
                                v-model="condition.catalog_id"
                                :options="select_catalogs"
                                empty_option="---"
                            ></form-select>
                            <form-select
                                v-model="condition.is_on_catalog"
                                :options="is_on_catalog"
                                empty_option="---"
                            ></form-select>
                        </div>
                    </div>
                    <div class="form-group col-md-4">
                        <label>ステータス</label>
                        <form-select
                            v-model="condition.availability"
                            :options="availabilities"
                            empty_option="全て"
                        ></form-select>
                    </div>
                </div>
                <div class="row align-items-end">
                    <!-- いずれかの編集権限を持つ場合 -->
                    <div class="form-group col-md-4" v-if="$store.getters['auth/canEditAny']()">
                        <label>編集ステータス</label>
                        <form-select
                            v-model="condition.published_status"
                            :options="published_status"
                            empty_option="全て"
                        ></form-select>
                    </div>
                    <!-- 製品基本情報編集権限を持つ場合 -->
                    <div class="form-group col-md-4" v-if="$store.getters['auth/canEditBasic']()">
                        <label>公開区分</label>
                        <form-select
                            v-model="condition.public_type"
                            :options="public_types"
                            empty_option="全て"
                        ></form-select>
                    </div>
                    <div class="col text-center text-md-end mt-2 mt-md-0">
                        <button-search
                            type="submit"
                            :disabled="search_loading"
                        ></button-search>
                    </div>
                </div>
            </form>
        </section>

        <section class="section">
            <inline-loader v-if="search_loading"></inline-loader>
            <template v-else>
                <search-result
                    :total="paginator.total"
                >
                    <template v-slot:right>
                        <button-exec
                            :icon="icon"
                            text="一括操作"
                            color=""
                            @click="toggleDrop"
                        ></button-exec>
                    </template>
                    <template v-slot:bottom>
                        <div class="p-3 border-top m-0" v-if="drop_down">
                            <form class="row align-items-center mb-2" @submit.prevent="createExportList">
                                <div class="col-md-3 fw-bold">エクスポート</div>
                                <div class="col-md-7">
                                    <form-select
                                        v-model="export_layout.export_layout_id"
                                        :options="select_export_layouts"
                                        empty_option="--形式を選択--"
                                        required="required"
                                    ></form-select>
                                </div>
                                <div class="col-md-4" v-if="is_product_retailer">
                                    <form-select
                                        v-model="export_layout.retailer_id"
                                        :options="select_retailers"
                                        empty_option="--商社を選択--"
                                        required="required"
                                    ></form-select>
                                </div>
                                <div class="col-md-6">
                                    <form-input
                                        v-model="export_layout.export_note"
                                        placeholder="メモ"
                                    ></form-input>
                                </div>
                                <div class="col-md-4 text-end">
                                    <button-exec
                                        type="submit"
                                        icon="bi-download"
                                        text="エクスポート"
                                    ></button-exec>
                                </div>
                            </form>
                            <form class="row align-items-center mb-2" @submit.prevent="createExportErrata">
                                <div class="col-md-3 fw-bold">正誤表</div>
                                <div class="col-md-7">
                                    <div class="input-group">
                                        <form-input-date
                                            v-model="errata.period_from"
                                        ></form-input-date>
                                        <span class="input-group-text">〜</span>
                                        <form-input-date
                                            v-model="errata.period_to"
                                        ></form-input-date>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <form-select
                                        v-model="errata.errata_type"
                                        :options="errata_types"
                                        empty_option="--形式--"
                                        required="required"
                                    ></form-select>
                                </div>
                                <div class="col-md-4">
                                    <form-input
                                        v-model="errata.export_note"
                                        placeholder="メモ"
                                    ></form-input>
                                </div>
                                <div class="col-md-2 text-end">
                                    <button-exec
                                        type="submit"
                                        text="作成"
                                    ></button-exec>
                                </div>
                            </form>
                            <!-- 製品基本情報編集権限を持つ場合 -->
                            <form class="row align-items-center mb-2" @submit.prevent="bulkUpdatePublishedStatus" v-if="$store.getters['auth/canEditBasic']()">
                                <div class="col-md-3 fw-bold">一括公開</div>
                                <div class="col-md-7">
                                    <form-select
                                        v-model="bulk_operation.target"
                                        :options="bulk_operation_targets"
                                        empty_option="--対象--"
                                        required="required"
                                    ></form-select>
                                </div>
                                <div class="col-md-4">
                                    <button-exec
                                        type="submit"
                                        text="一括公開"
                                        color="btn-outline-primary"
                                    ></button-exec>
                                </div>
                            </form>
                        </div>
                    </template>
                </search-result>

                <template v-if="product_groups.length">
                    <!-- ページャー -->
                    <paginator :meta="paginator" @move="search"></paginator>
                    <table-normal>
                        <thead>
                            <tr class="table-dark">
                                <th class="text-center col-1">
                                    <input type="checkbox"
                                        v-model="check_all"
                                        class="form-check-input"
                                    >
                                </th>
                                <th class="text-center col-3">製品グループコード</th>
                                <th class="text-center col-7">製品グループ名/オプション名</th>
                                <th class="text-center col-3">カタログ掲載</th>
                                <th class="text-center col-3">最終公開日</th>
                                <th class="text-center col-3"></th>
                                <th class="text-center col-2"></th>
                                <th class="text-center col-2"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product_group in product_groups" :key="product_group.product_group_id" :class="{'bg-light': product_group.published_status === PublishedStatus.DRAFT}">
                                <td class="align-middle text-center">
                                    <input type="checkbox"
                                        :value="product_group.product_group_id"
                                        v-model="bulk_operation.product_group_ids"
                                        class="form-check-input"
                                    >
                                </td>
                                <td class="align-middle">{{ product_group.product_group_code }}</td>
                                <td class="align-middle">{{ product_group.product_group_name}} <span class="text-secondary small">{{ product_group.option_name }}</span></td>
                                <td class="align-middle text-center">
                                    <template v-if="Number(product_group.is_on_catalog) === IsOnCatalog.YES">
                                        <template v-if="product_group.catalog_page_numbers">
                                            {{ product_group.catalog_page_numbers }}
                                        </template>
                                        <template v-else>掲載</template>
                                    </template>
                                    <template v-else>-</template>
                                </td>
                                <td class="align-middle text-center">
                                    {{ product_group.latest_published_date ? product_group.latest_published_date : '-' }}
                                </td>
                                <td class="align-middle text-center">
                                    <i
                                        class="me-1 bi bi-file-earmark-richtext"
                                        :class="{'text-primary': product_group.has_product_group_flyer, 'text-secondary': !product_group.has_product_group_flyer}"
                                        :title="product_group.has_product_group_flyer ? 'チラシあり' : 'チラシなし'"
                                    ></i>
                                    <i
                                        class="mx-1 bi bi-film"
                                        :class="{'text-primary': product_group.has_product_group_movie, 'text-secondary': !product_group.has_product_group_movie}"
                                        :title="product_group.has_product_group_movie ? '動画あり' : '動画なし'"
                                    ></i>
                                    <i
                                        class="mx-1 bi bi-slash-circle"
                                        :class="{'text-danger': product_group.pending_date, 'text-secondary': !product_group.pending_date}"
                                        :title="product_group.pending_date ? '取引停止' : '取引中'"
                                    ></i>
                                    <i
                                        class="mx-1 bi bi-cart"
                                        :class="{'text-info': product_group.has_product_retailer, 'text-secondary': !product_group.has_product_retailer}"
                                        :title="product_group.has_product_retailer ? '商社(販売中)' : '商社'"
                                    ></i>
                                    <i
                                        class="mx-1 bi bi-pencil-square"
                                        :class="{'text-success': product_group.published_status !== PublishedStatus.PUBLISHED, 'text-secondary': product_group.published_status === PublishedStatus.PUBLISHED }"
                                        :title="product_group.published_status !== PublishedStatus.PUBLISHED ? '下書きあり' : '下書きなし'"
                                    ></i>
                                    <!-- <i
                                        class="ms-1 bi bi-lock-fill"
                                        :class="{'text-warning': product_group.public_type !== PublicType.NORMAL, 'text-secondary': !product_group.has_product_retailer}"
                                        :title="product_group.public_type !== PublicType.NORMAL ? '非公開' : '公開'"
                                    ></i> -->
                                </td>
                                <td class="align-middle text-center">
                                    <!-- 下書きのみの場合 リビジョン詳細へ -->
                                    <template v-if="product_group.published_status === PublishedStatus.DRAFT">
                                        <button-go-view
                                            :to="{name: 'ProductGroupRevisionView', params: {id: product_group.product_group_id, revision: 1}}"
                                        ></button-go-view>
                                    </template>
                                    <!-- 一度でも公開の場合 公開詳細へ -->
                                    <button-go-view
                                        v-else
                                        :to="{name: 'ProductGroupView', params: {id: product_group.product_group_id}}"
                                    ></button-go-view>
                                </td>
                                <td class="align-middle text-center">
                                    <!-- いずれかの編集権限を持つ場合 -->
                                    <button-go-update
                                        v-if="$store.getters['auth/canEditAny']()"
                                        :to="{name: 'ProductGroupEdit', params: {id: product_group.product_group_id}}"
                                    ></button-go-update>
                                </td>
                            </tr>
                        </tbody>
                    </table-normal>
                    <!-- ページャー -->
                    <paginator :meta="paginator" @move="search"></paginator>
                </template>
                <alert-no-record v-else></alert-no-record>
            </template>
        </section>
    </template>
</template>

<script>
import ExportLayout from '@/models/entities/export-layout';
import ProductGroup from '@/models/entities/product-group';
import BulkOperationTarget from '@/models/enums/bulk-operation-target';
import ErrataType from '@/models/enums/errata-type';
import IsOnCatalog from '@/models/enums/is-on-catalog';
import LayoutType from '@/models/enums/layout-type';
import Availability from '@/models/enums/availability';
import PublicType from '@/models/enums/public-type';
import PublishedStatus from '@/models/enums/published-status';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import Paginator from '@/components/tools/Paginator';
import FormInput from '@/components/forms/FormInput';
import FormInputDate from '@/components/forms/FormInputDate';
import FormSelect from '@/components/forms/FormSelect';
import ButtonExec from '@/components/buttons/ButtonExec';
import ButtonSearch from '@/components/buttons/ButtonSearch';
import ButtonGoView from '@/components/buttons/ButtonGoView.vue';
import ButtonGoUpdate from '@/components/buttons/ButtonGoUpdate.vue';
import TableNormal from '@/components/tables/TableNormal.vue';
import SearchResult from '@/components/notice/SearchResult.vue';
import AlertNoRecord from '@/components/notice/AlertNoRecord.vue';
import PageTitle from '@/components/PageTitle.vue';

export default {
    name: 'ProductGroupList',
    components: {
        InlineLoader,
        Paginator,
        FormInput,
        FormInputDate,
        FormSelect,
        ButtonExec,
        ButtonSearch,
        ButtonGoView,
        ButtonGoUpdate,
        TableNormal,
        SearchResult,
        AlertNoRecord,
        PageTitle,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage',
    ],
    data() {
        return {
            //部分ローダー
            fetch_loading: 0,
            search_loading: 0,

            //検索オプション
            condition: {
                code: null,
                keyword: null,
                catalog_id: null,
                is_on_catalog: null,
                published_status: PublishedStatus.PUBLISHED,
                availability: Availability.EXCEPT,
                public_type: PublicType.NORMAL,
                page: 1,
            },
            //エクスポート
            export_layout: {
                export_layout_id: null,
                retailer_id: null,
                export_note: null
            },
            //正誤表
            errata: {
                period_from: null,
                period_to: null,
                catalog_id: null,
                errata_type: null,
                export_note: null
            },
            //一括操作
            bulk_operation: {
                target: null,
                product_group_ids: [],
            },
            //一括操作対象の製品グループIDs

            //enums
            BulkOperationTarget: BulkOperationTarget,
            LayoutType: LayoutType,
            Availability: Availability,
            PublicType: PublicType,
            PublishedStatus: PublishedStatus,
            IsOnCatalog: IsOnCatalog,

            //選択肢
            bulk_operation_targets: BulkOperationTarget.options(),
            errata_types: ErrataType.options(),
            is_on_catalog: IsOnCatalog.options(),
            published_status: PublishedStatus.options(),
            availabilities: Availability.options(),
            public_types: PublicType.options(),
            select_catalogs: [],
            select_export_layouts: [],
            select_retailers: [],

            //表示データ
            product_groups: [],
            export_layouts: [],
            icon: 'bi-chevron-down',
            drop_down: false,

            //ページネーター（ページャー）
            paginator: {},
        }
    },
    mounted() {
        //storeに前回の検索条件・ページングページがあれば
        this.condition = this.$store.getters['condition/merge']('ProductGroupList', this.condition);
        //編集ステータスが非表示のユーザ（製品基本情報編集権限を持たない）は自動的に編集ステータスが「公開」が選択されているものとする
        if (!this.$store.getters['auth/canEditBasic']()) {
            this.condition.published_status = PublishedStatus.PUBLISHED;
            this.condition.public_type = PublicType.NORMAL;
        } else {
            // キーが存在しなければ補完
            if (false === 'published_status' in this.condition || typeof this.condition.published_status === "undefined") {
                this.condition.published_status = null;
            }
            if (false === 'public_type' in this.condition || typeof this.condition.public_type === "undefined") {
                this.condition.public_type = null;
            }
        }

        this.fetchCatalogs(); // カタログを取得してから検索をかける
        this.fetchExportLayouts();
        this.fetchRetailers();
    },
    computed: {
        //エクスポートデータの一括選択
        check_all: {
            get() {
                if (this.bulk_operation.product_group_ids.length === this.product_groups.length) {
                    //すべてチェックを入れるとselectAllはtrueとなりチェックが入る
                    return true;

                } else {
                    return false;
                }
            },
            set(newValue) {
                let checked_ids = this.bulk_operation.product_group_ids;

                //全選択にチェックが入った場合すべての値にチェックを入れる
                if (newValue) {
                    this.product_groups.forEach(function (product_group) {
                        if (!checked_ids.includes(product_group.product_group_id)) {
                            checked_ids.push(product_group.product_group_id);
                        }
                    });
                } else {
                    this.product_groups.forEach(function (product_group) {
                        let index = checked_ids.indexOf(product_group.product_group_id)

                        if (index > -1) {
                            checked_ids.splice(index, 1);
                        }
                    });

                    this.bulk_operation.product_group_ids = checked_ids;
                }
            }
        },
        //選択したエクスポート定義のタイプが「製品×商社」かどうか
        is_product_retailer() {
            //エクスポート定義がなかったり、エクスポート定義のIDが選択されていない場合はfalse
            if (!this.export_layouts.length || !this.export_layout.export_layout_id) {
                return false;
            }

            //選択したエクスポート定義IDの定義タイプが「製品×商社」ではない場合はfalse
            let target_export_layout = this.export_layouts.find(export_layout => {
                return export_layout.export_layout_id === this.export_layout.export_layout_id;
            });
            if (target_export_layout.layout_type !== LayoutType.PRODUCT_RETAILER) {
                return false
            }

            return true;
        }
    },
    methods: {
        //検索
        search(page = 1) {
            this.search_loading++;
            this.condition.page = page;

            //表示データ初期化
            this.product_groups.splice(0);

            this.$http.get('/product-group', {
                params: this.condition
            })
            .then(response => {
                for (let row of response.data.data) {
                    this.product_groups.push(new ProductGroup(row));
                }
                //ページネーション用
                this.paginator = response.data.meta;
                //検索条件を保存
                this.$store.commit('condition/setSearchCondition', {
                    page: 'ProductGroupList',
                    condition: this.condition
                });
            })
            .finally(() => {
                this.search_loading--;
            });
        },
        //カタログマスタより選択肢を取得
        fetchCatalogs() {
            this.fetch_loading++;

            this.$http.get('/catalog')
            .then(response => {
                for (let row of response.data) {
                    this.select_catalogs.push({key: row.catalog_id, label: row.catalog_name});
                }
            })
            .finally(() => {
                this.fetch_loading--;
                this.search();
            });
        },
        //エクスポート定義マスタより選択肢を取得
        fetchExportLayouts() {
            this.fetch_loading++;

            this.$http.get('/export-layout')
            .then(response => {
                for (let row of response.data) {
                    this.export_layouts.push(new ExportLayout(row));
                    this.select_export_layouts.push({key: row.export_layout_id, label: row.export_layout_name});
                }
            })
            .finally(() => {
                this.fetch_loading--;
            });
        },
        //商社マスタより選択肢を取得
        fetchRetailers() {
            this.fetch_loading++;

            this.$http.get('/retailer')
            .then(response => {
                for (let row of response.data) {
                    this.select_retailers.push({key: row.retailer_id, label: row.retailer_name});
                }
            })
            .finally(() => {
                this.fetch_loading--;
            });
        },
        //一括操作の出し入れ
        toggleDrop() {
            if (this.drop_down) {
                this.drop_down = false;
                this.icon = 'bi-chevron-down';
            } else {
                this.drop_down = true;
                this.icon = 'bi-chevron-up'
            }
        },
        //エクスポートトランにヘッダー一覧を追加
        createExportList() {
            this.startScreenLoading();

            //エクスポート形式が選択されていなければエラー
            if (!this.export_layout.export_layout_id) {
                this.showErrorMessage('エクスポート形式を選択してください');
                this.endScreenLoading();
                return;
            }

            //「製品×商社」のときに、商社が選択されていなければエラー
            if (this.is_product_retailer && !this.export_layout.retailer_id) {
                this.showErrorMessage('商社を選択してください');
                this.endScreenLoading();
                return;
            }
            this.$http.put(`/export/list`, {
                export_layout: this.export_layout,
                condition: this.condition
            })
            .then(() => {
                this.showMessage('予約しました');
                this.$router.push({name: 'ExportJobList'});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //エクスポートトランに正誤表を追加
        createExportErrata() {
            this.startScreenLoading();

            //正誤表タイプが選択されていなければエラー
            if (!this.errata.errata_type) {
                this.showErrorMessage('正誤表の形式を選択してください');
                this.endScreenLoading();
                return;
            }

            this.$http.put(`/export/errata`, {
                errata: this.errata,
                condition: this.condition
            })
            .then(() => {
                this.showMessage('予約しました');
                this.$router.push({name: 'ExportJobList'});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //製品グループを一括公開する
        bulkUpdatePublishedStatus() {
            this.startScreenLoading();

            //対象が選択されていなければエラー
            if (!this.bulk_operation.target) {
                this.showErrorMessage('対象を選択してください');
                this.endScreenLoading();
                return;
            }
            // チェックした商品 を一括操作の場合
            if (
                this.bulk_operation.target === BulkOperationTarget.CHECKED
                && this.bulk_operation.product_group_ids.length === 0
            ) {
                this.showErrorMessage('製品が選択されていません。下表よりチェックボックスで選択してください。');
                this.endScreenLoading();
                return;
            }

            this.$http.put(`/product-group/bulk-publish`, {
                bulk_operation: this.bulk_operation,
                condition: this.condition
            })
            .then(() => {
                this.showMessage('一括公開しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        }
    },
    watch: {
        //製品一覧→製品一覧の、同一ページ遷移のためのroute watch（queryで判定）
        $route ($to, $from) {
            if ($to.query !== $from.query) {
                location.reload();
            }
        }
    },
}
</script>

<style scoped>

</style>
