import Enum from './enum'

/**
 * エクスポート定義タイプ
 */
class LayoutType extends Enum {
    static PRODUCT_GROUP = 1; // 製品グループ
    static SUBGROUP = 2; // サブグループ
    static PRODUCT = 3; // 製品
    static PRODUCT_RETAILER = 4; // 製品×商社
    static COMPONENT = 5; // 構成品

    static values() {
        return {
            [this.PRODUCT_GROUP]: '製品グループ',
            [this.SUBGROUP]: 'サブグループ',
            [this.PRODUCT]: '製品',
            [this.PRODUCT_RETAILER]: '製品×商社',
            [this.COMPONENT]: '構成品',
        }
    }
}

export default LayoutType;
