/**
 * エクスポート定義マスタ エンティティ
 */
import { isNud } from '@/utilities/typing';
import { dateYmdHi } from '@/utilities/date-format';
import ExportLayoutDetail from '@/models/entities/export-layout-detail';
import User from '@/models/entities/user';
import LayoutType from "@/models/enums/layout-type"

class ExportLayout {
    export_layout_id;
    export_layout_name;
    layout_type;
    required_can_view_production;
    modified_user;
    modified_datetime;
    details;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.export_layout_id = properties.export_layout_id;
        this.export_layout_name = properties.export_layout_name;
        this.layout_type = properties.layout_type;
        this.required_can_view_production = properties.required_can_view_production;
        this.modified_user = new User(properties.modified_user);
        this.modified_datetime = properties.modified_datetime;
        this.details = [];
        if ('details' in properties) {
            for (let detail of properties.details) {
                this.details.push(new ExportLayoutDetail(detail));
            }
        }
    }

    get layout_type_display() {
        return LayoutType.get(this.layout_type);
    }

    get modified_datetime_display() {
        return dateYmdHi(this.modified_datetime);
    }
}

export default ExportLayout;
